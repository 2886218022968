import { motion } from 'framer-motion';
import { Mail, Phone, Globe, Send } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations';

interface FormData {
  name: string;
  email: string;
  message: string;
}

export const TalkToUs = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language];
  
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus('idle');

    try {
      const response = await fetch('https://hook.eu1.make.com/rqli62ifcuzk8xx8r212164oj6bryo2i', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) throw new Error('Submission failed');

      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
      
      // Show success message for 2 seconds, then redirect
      setTimeout(() => {
        navigate('/');
      }, 2000);
    } catch (error) {
      console.error('Submission error:', error);
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="min-h-screen bg-[#0A0A0A] py-20">
      <div className="container max-w-4xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="bg-[#1A1A1A] rounded-2xl p-6 sm:p-8 border border-white/10"
        >
          <h1 className="text-2xl sm:text-3xl font-bold text-white mb-2">
            {t.contact.title}
          </h1>
          <p className="text-[#DADADA] mb-8">
            {t.contact.subtitle}
          </p>

          {submitStatus === 'success' && (
            <div className="bg-green-500/10 text-green-500 px-4 py-3 rounded-lg mb-6">
              {t.contact.success}
            </div>
          )}

          {submitStatus === 'error' && (
            <div className="bg-red-500/10 text-red-500 px-4 py-3 rounded-lg mb-6">
              {t.contact.error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm text-white/70">
                {t.contact.form.name}
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-white/30 focus:outline-none focus:border-purple-500/50"
                placeholder={t.contact.form.name}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="email" className="text-sm text-white/70">
                {t.contact.form.email}
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-white/30 focus:outline-none focus:border-purple-500/50"
                placeholder={t.contact.form.email}
                required
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="message" className="text-sm text-white/70">
                {t.contact.form.message}
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                value={formData.message}
                onChange={handleChange}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-3 text-white placeholder-white/30 focus:outline-none focus:border-purple-500/50"
                placeholder={t.contact.form.message}
                required
              />
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-gradient-to-r from-purple-500 to-pink-500 text-white py-3 rounded-lg flex items-center justify-center gap-2 transition-all ${
                isSubmitting ? 'opacity-70 cursor-not-allowed' : 'hover:opacity-90'
              }`}
            >
              <Send className="w-4 h-4" />
              {isSubmitting ? t.contact.form.sending : t.contact.form.submit}
            </motion.button>
          </form>

          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center gap-3 text-white/70">
              <Mail className="w-5 h-5" />
              <span>hello@willowwisp.io</span>
            </div>
            <div className="flex items-center gap-3 text-white/70">
              <Phone className="w-5 h-5" />
              <span>+49 176 553 74 328</span>
            </div>
            <div className="flex items-center gap-3 text-white/70">
              <Globe className="w-5 h-5" />
              <span>www.willowwisp.io</span>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};