import { motion } from 'framer-motion';
import { ArrowRight, Volume2, VolumeX, Linkedin } from 'lucide-react';
import { XIcon } from './icons/XIcon';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations';
import { useState, useEffect, useRef } from 'react';

export const Hero = () => {
  const { language } = useLanguage();
  const t = translations[language];
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    audioRef.current = new Audio('/mixed_speech.mp3');
    
    const handleEnded = () => {
      setIsPlaying(false);
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
      }
    };
    
    audioRef.current.addEventListener('ended', handleEnded);

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleEnded);
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      } else {
        audioRef.current.currentTime = 0;
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTalkToUs = () => {
    navigate('/talk-to-us');
  };

  return (
    <section className="min-h-[90vh] sm:min-h-screen bg-[#0A0A0A] relative overflow-hidden flex items-center pt-20 sm:pt-14">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(0,0,0,0)_0%,#0A0A0A_100%)]" />
      </div>
      <motion.button
        onClick={toggleAudio}
        className="fixed sm:top-6 top-20 right-6 z-50 bg-white/10 hover:bg-white/20 p-3 rounded-full backdrop-blur-sm border border-white/10 transition-colors"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        {isPlaying ? (
          <Volume2 className="w-5 h-5 text-white" />
        ) : (
          <VolumeX className="w-5 h-5 text-white" />
        )}
      </motion.button>
      <div className="container max-w-[1200px] mx-auto px-4 sm:px-6 relative z-10">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto text-center"
        >
          <div className="flex flex-col items-center gap-4 mb-6">
            <img 
              src="/images/logo.png" 
              alt="Willow Wisp Logo" 
              className="h-12 w-auto"
            />
            <h2 className="text-lg sm:text-xl text-white font-normal">Willow Wisp</h2>
            <div className="flex items-center gap-2 bg-white/5 px-3 py-1.5 rounded-full border border-white/10 backdrop-blur-sm">
              <motion.div
                initial={{ rotate: 0 }}
                animate={{ rotate: 360 }}
                transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
              >
                <div className="w-4 h-4 rounded-full bg-gradient-to-r from-[#000000] via-[#FF0000] to-[#FFD700]" />
              </motion.div>
              <span className="text-xs text-white/70 font-medium tracking-wide uppercase">
                Made in Germany
              </span>
            </div>
            
            {/* Social Media Links */}
            <div className="flex items-center gap-4 mt-2">
              <motion.a
                href="https://x.com/wXwisp"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className="bg-white/5 p-2 rounded-full hover:bg-white/10 transition-colors"
              >
                <XIcon className="w-5 h-5 text-white" />
              </motion.a>
              <motion.a
                href="https://www.linkedin.com/company/willow-x-wisp/about/"
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                className="bg-white/5 p-2 rounded-full hover:bg-white/10 transition-colors"
              >
                <Linkedin className="w-5 h-5 text-white" />
              </motion.a>
            </div>
          </div>

          <h1 className="text-2xl sm:text-3xl md:text-5xl lg:text-6xl font-bold text-white mb-6 sm:mb-8 tracking-tight leading-tight">
            {t.hero.title}
            <br className="hidden sm:block" />
            <span className="sm:inline">{t.hero.subtitle}</span>
          </h1>

          <p className="text-sm sm:text-base md:text-lg text-[#DADADA] mb-6 sm:mb-8 max-w-2xl mx-auto leading-relaxed px-4 sm:px-0">
            {t.hero.description}
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-3 sm:gap-4 px-4 sm:px-0">
            <motion.button
              onClick={handleTalkToUs}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-5 sm:px-6 py-2.5 sm:py-3 rounded-full text-xs sm:text-sm font-medium flex items-center gap-2 hover:opacity-90 transition-opacity w-full sm:w-auto justify-center shadow-lg shadow-purple-500/25"
            >
              {t.hero.cta} <ArrowRight className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
            </motion.button>
          </div>
          <p className="text-[#DADADA] mt-4 sm:mt-6 text-[10px] sm:text-xs">
            {t.hero.companies}
          </p>
        </motion.div>
      </div>
    </section>
  );
};